.news-div{
    padding: 30px;
    margin-top: 10px;
}
h3{
    margin: 0px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.news-div p{
    margin-top: 0px;
}
h3::after{
    content: "";
    background-color: var(--bar-color);
    width: 110px;
    height: 5px;
    border-radius: 10px;
    margin: 5px 0px;
}
