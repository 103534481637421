.moi img{
    margin-top: 9px;
}
.tabl{
    display: flex;
    justify-content: space-between;
    width: 51%;
}
@media only screen and (max-width:766px){
    .tabl{
        width: 100%;
    }
}
.one{
    display: flex;
}
.one-sec{
    margin: 0px 15px;
}
.moi p{
    margin-top: 0px;
    text-align: left;
    font-size: 25px;
}
.dplist{
    margin-left: 24px;
}