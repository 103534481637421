.gallery-content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.faculty-div{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}