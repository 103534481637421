
 .slider{
    /* border: 1px solid red; */
}

.slider_card_body{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.slider_card{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.react-multi-carousel-dot button{
    border: none;
}
.react-multi-carousel-dot--active button{
    background-color: var(--bar-color);
}
.card_crusel{
    margin: 23px 2.5em;
    width: 296px;
}
@media only screen and (max-width:350px){
    .card_crusel{
        margin: 23px 1.5em;
        width: 200px;
    }
}