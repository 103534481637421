.contact-cont{
   margin: 0;
   padding: 0;
   
}
.cont-title{
    margin-bottom: 40px;
  }
  /* .card{
    height: 40%;
  } */
  .map{
    margin-top: 30px;
  }
  @media (max-width:600px) {
    iframe {
     width: 100%;
    }
   }
  .my{
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     flex-wrap: wrap;
  }
body {
    /* background-color: #f5e0e5 !important; */
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .contact-form-wrapper {
    padding: 0px 0;
  }
  
  .contact-form {
    margin-top: 30px;
    padding: 30px 40px;
    background-color: #ffffff;
    max-width: 100%;
    box-shadow: 2px 6px 10px 9px #eaeaea;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form .form-input,
  .form-text-area {
    background-color: #fbfbfb;
    box-shadow: 1px 2px 5px 2px #eaeaea;
    height: 50px;
    padding-left: 16px;
  }
  
  .contact-form .form-text-area {
    background-color: #fbfbfb;
    box-shadow: 1px 2px 5px 2px #eaeaea;
    height: auto;
    padding-left: 16px;
  }
  
  .contact-form .form-control::placeholder {
    color: #aeb4b9;
    font-weight: 500;
    opacity: 1;
  }
  
  .contact-form .form-control:-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control::-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control:focus {
    /* border-color: #F1C93B; */
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #F1C93B;
  }
  
 
  
  .contact-form .description {
    color: #aeb4b9;
    font-size: 14px;
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper {
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper input {
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 1px 3px 7px 5px #eaeaea;
    color: rgb(68, 68, 68);
    text-transform: uppercase;
    padding: 4px 60px;
    font-weight: 500;
    letter-spacing: 2px;
    transition: 0.7s;
  }
  
  .contact-form .submit-button-wrapper input:hover {
    background-color: #F1C93B;
  }
  .cont-detail{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .card{
    border: none;
    box-shadow: 1px 3px 7px 5px #eaeaea;
    border-radius: 0px;
  }
  .card-title{
    font-weight: 500;
    text-align: center;
    font-size: 18px;
    margin: 0px;
    color: black;
  }
  .card-text{
    padding: 0px;
  }
  .card-body a{
text-decoration: none;
  }
  .card-body h5{
    transition: 0.3s;
  }
  .card-body h5:hover{
    color: var(--bar-color)
  }
  /* --------------------------------------ICON---------------------------------- */

  .email svg{
    font-size: 30px;
    color: var(--bar-color);
  }
  
.email{
  display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}