.main-table-heading{
    text-align: center;
    font-size: 19px;
}
.sec-table-heading{
   
        text-align: center;
        font-size: 18px;
    
}
.vacancy-table{
    margin: 10px;
}
.vacancy-mid{
    text-align: center;
}
