h3{
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.news-div p{
    margin-top: 0px;
    text-align: center;
}
h3::after{
    content: "";
    background-color: var(--bar-color);
    width: 110px;
    height: 5px;
    border-radius: 10px;
   
}
.card-text{
    margin-top: 0px;
}
.event_detail p{
    text-align: left;
    margin: 0px 15px;
}
.event_detail{
    margin-top: 20px;
}
.loading-spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}