
.gallery-cont img{
    width: 30%;
    margin: 15px 10px;
}
@media only screen and (max-width:625px){
    .gallery-cont img{
        width: 100%;
    }
}
.gallery-cont{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  
}
.degree-btn{
    font-size: 17px;
}