.degree-content{
    margin-top: 27px;
    margin-left: 38px;
}
.degree-content p{
   text-align: left;
}
.degree-content h2{
   font-size: 24px;
}

.time p{
   text-align: left;
}
.degree_Pic{
   width: 70%;
}