
.pride img{
   width: 70px;
   height: 70px;
}
.pride_body{
    background-color: white;
    margin-left: 30px;
    margin-top: 15px;
    
}
.top{
    margin-bottom: 40px;
}
.pride_body h5, h6{
    margin-bottom: 0px;
}
.pride_body h6{
  color: #898b96;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
}
.pride{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.Pride_card{
    height: 100%;
    box-shadow: 1px 2px 5px 2px #eaeaea;
    width: 300px;
    padding: 20px;
    margin: 0px 20px;
}